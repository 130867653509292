@import "../../global.scss";
.menu {
  height: 100vh;
  width: 100vw;
  z-index: 2;
  background-color: $mainColor;
  position: fixed;
  top: 0;
  right: -100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.8s ease;
  &.active {
    right: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    list-style: none;
    font-size: 60px;
    font-weight: 300;
    color: white;
    width: 60%;
    height: 60%;

    @include mobile {
      font-size: 40px;
    }

    li {
      margin-bottom: 25px;
      a {
        font-size: inherit;
        color: inherit;
        text-decoration: none;
      }
      &:hover {
        font-weight: bold;
        color: rgb(238, 144, 144);
      }
    }
  }
}
