@import "../../global.scss";

.contact {
  background-color: white;
  border-top: 5px solid lightcoral;
  display: flex;
  position: relative;
  @include mobile {
    flex-direction: column;
  }

  .left {
    flex: 1;
    width: 600px;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      position: absolute;
      width: 150px;
      height: 100%;
      bottom: 280px;
      right: 0;
    }

    img {
      height: 100%;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 30px;
    }

    form {
      width: 100%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      input {
        width: 50%;
        height: 50px;
        border: none;
        border-bottom: 1px solid black;
        margin: 10px 0px;
        font-size: 14px;
        padding-left: 10px;

        @include mobile {
          width: 300px;
          margin: 5px 0px;
          height: 30px;
        }
      }

      textarea {
        width: 90%;
        height: 60%;
        margin: 10px;
        font-size: 14px;
        padding: 10px;

        @include mobile {
          width: 300px;
          height: 200px;
        }
      }

      button {
        width: 150px;
        height: 30px;
        color: white;
        background-color: crimson;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;
        @include mobile {
          margin-top: 10px;
        }
        &:focus {
          outline: none;
        }
      }

      span {
        color: green;
      }
    }
  }
}
