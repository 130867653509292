@import "../../global.scss";

.about-container {
  border-top: rgb(228, 94, 94) 5px solid;
  display: flex;
  height: calc(100vh - 70px);
  flex-direction: column;
  .top {
    flex: 0.7;
    overflow: hidden;
    display: flex;

    @include mobile {
      overflow: hidden;
      flex: 1;
    }
    .stuff {
      height: 100vh;
      display: flex;
      @include mobile {
        display: flex;
        height: 100%;
        flex-direction: column;
        font-size: 13px;
        overflow: hidden;
      }
      .about-me {
        flex: 0.5;

        padding: 35px;
        background-color: #2a2e33;
        @include mobile {
          font-size: 15px;
          padding: 10px;
        }

        h1 {
          color: white;
          margin-bottom: 10px;

          font-size: 30px;
        }
        p {
          margin: 0;
          color: white;
          width: 100%;
          font-size: 22px;
          letter-spacing: 1.5px;
          font-weight: 200;
          @include mobile {
            font-size: 15px;
          }
        }
      }
      .random-stuff {
        @include mobile {
          font-size: 15px;
          padding: 10px;
          width: 100%;
        }
        padding: 20px;
        flex: 0.5;
        padding: 35px;
        h1 {
          font-size: 30px;
          color: rgb(24, 22, 22);
        }
        ul {
          list-style-position: inside;

          @include mobile {
            font-size: 15px;
          }
        }
        li {
          font-size: 22px;
          font-weight: 300;
          padding-top: 10px;
          display: inline-block;
          @include mobile {
            font-size: 15px;
            // padding: 15px;
            width: 100%;
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;

    flex: 0.3;
    background-color: #c9c9c9;

    justify-content: space-around;
    flex-direction: column;
    padding: 30px;
    @include mobile {
      display: none;
    }
    .image-container {
      display: flex;
      padding-top: 40px;
      padding-left: 60px;
    }
    h1 {
      color: rgb(24, 24, 24);
    }
  }
}
